import { Link as RouterLink } from 'react-router-dom';
import ArrowRightMd from '../../icons/ArrowRightMd';
import styles from './Link.module.css';
import { useState } from 'react';

interface LinkProps {
  to: string;
  variant?: 'primary' | 'secondary';
}

const Link = ({ to, variant = 'primary' }: LinkProps) => {
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);

  return (
    <RouterLink
      to={to}
      className={`${styles.link} ${activeClass} ${styles[variant]}`}
      onMouseEnter={() => setActiveClass(styles.mouseEnter)}
      onMouseLeave={() => setActiveClass(styles.mouseLeave)}>
      <span>Read more</span>
      <ArrowRightMd />
    </RouterLink>
  );
};

export default Link;
