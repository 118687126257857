import { SVGProps } from "react";
// #0058A0
const ArrowRightMd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={24} height={24} fill="#fff" rx={12} />
      <path
        stroke="#0058A0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.167 12h11.667m0 0L12 6.167M17.834 12 12 17.833"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={24} height={24} fill="#fff" rx={12} />
      </clipPath>
    </defs>
  </svg>
);
export default ArrowRightMd;
