import { SVGProps } from "react";
const Burger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="18"
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1H27" stroke="black" stroke-width="2" stroke-linecap="round" />
    <path d="M1 9H21" stroke="black" stroke-width="2" stroke-linecap="round" />
    <path d="M1 17H27" stroke="black" stroke-width="2" stroke-linecap="round" />
  </svg>
);
export default Burger;
