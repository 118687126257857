import { Link, useParams } from 'react-router-dom';
import styles from './Article.module.css';
import articles from '../../api/articles.json';
import moment from 'moment';

const Article = () => {
  const { id } = useParams<{ id: string }>();
  const article = articles.find((article) => article.id === +id!);

  // Function to replace link markers in text with clickable links
  // const renderTextWithLinks = (text: string, links: { [key: string]: { url: string; linkText: string } } = {}) => {
  //   const linkRegex = /\[(link\d+)\]/g;
  //   return text.split(linkRegex).map((part, index) => {
  //     if (links[part]) {
  //       const { url, linkText } = links[part];
  //       return (
  //         <a key={index} href={url} target="_blank" rel="noopener noreferrer">
  //           {linkText}
  //         </a>
  //       );
  //     }
  //     return part;
  //   });
  // };
/*
  // Function to replace link markers in text with clickable links or images
const renderTextWithLinks = (
  text: string,
  links: { [key: string]: { url: string; linkText?: string; imageSrc?: string } } = {}
) => {
  const linkRegex = /\[(link\d+)\]/g;
  return text.split(linkRegex).map((part, index) => {
    if (links[part]) {
      const { url, linkText, imageSrc } = links[part];
      return (
        <a key={index} href={url} target="_blank" rel="noopener noreferrer">
          {imageSrc ? (
            <img src={imageSrc} alt={linkText || 'Linked image'} style={{ maxWidth: '100px', height: 'auto' }} />
          ) : (
            linkText
          )}
        </a>
      );
    }
    return part;
  });
}; */

/*
// Function to replace link markers in text with clickable links or images, with optional size control
const renderTextWithLinks = (
  text: string,
  links: { [key: string]: { url: string; linkText?: string; imageSrc?: string; width?: string; height?: string } } = {}
) => {
  const linkRegex = /\[(link\d+)\]/g;
  return text.split(linkRegex).map((part, index) => {
    if (links[part]) {
      const { url, linkText, imageSrc, width = '10px', height = 'auto' } = links[part];
      return (
        <a key={index} href={url} target="_blank" rel="noopener noreferrer">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={linkText || 'Linked image'}
              style={{ maxWidth: width, height: height }}
            />
          ) : (
            linkText
          )}
        </a>
      );
    }
    return part;
  });
}; */

// Function to replace link markers in text with clickable links or images, with strict size control
const renderTextWithLinks = (
  text: string,
  links: { [key: string]: { url: string; linkText?: string; imageSrc?: string; width?: string; height?: string; maxWidth?: string; maxHeight?: string } } = {}
) => {
  const linkRegex = /\[(link\d+)\]/g;
  return text.split(linkRegex).map((part, index) => {
    if (links[part]) {
      const { url, linkText, imageSrc, width = '100px', height = 'auto', maxWidth = '100px', maxHeight = 'auto' } = links[part];
      const isExternal = url.startsWith("http"); // Check if the link is external

      return (
        <a key={index} href={url} 
          target={isExternal ? "_blank" : "_self"} // Open in a new tab only if it's an external link
          rel={isExternal ? "noopener noreferrer" : undefined} // Add security attributes for external links 
        >
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={linkText || 'Linked image'}
              style={{
                width: width,
                height: height,
                maxWidth: maxWidth,
                maxHeight: maxHeight,
                objectFit: 'contain' // Ensures the image fits within the set bounds without distortion
              }}
            />
          ) : (
            linkText
          )}
        </a>
      );
    }
    return part;
  });
};




  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.breadcrumbs}>
          Home &gt; News &gt; {article?.header}
        </div>

        <div className={styles.imgContainer}>
          <img
            className={styles.img}
            src={article?.labelImage}
            alt='img'
          />
          <span 
            className={styles.articleDate} 
            
            style={{
              position: 'absolute',
              top: '-00px', // Adjust to position just outside the top
              right: '90px', // Adjust to position just outside the right side
              // background: 'rgba(255, 255, 255, 0.8)', // Optional: background for better readability
              padding: '2px 5px', // Optional: padding around the date
              borderRadius: '4px' // Optional: rounded corners for the date
            }}
            >
            {moment(article?.date).format('DD MMM YYYY')}

          </span>
        </div>

      </div>
      <div className={styles.innerWrapper}>
        <div style={{ margin: '0 auto' }}>
          <h2 className={styles.heading}>{article?.header}</h2>
          {article?.intro1.map((text, index) => (
            <p key={index} className={styles.subHeading}>{text}</p>
          ))}
          {article?.intro2.map((text, index) => (
            <p key={index} className={styles.subHeading}>{text}</p>
          ))}


          <span className={styles.by}>By {article?.by}</span>   
          {/* Rendering each section with headings, texts, and replacing link markers */}
          {article?.headings.map((heading, index) => (
            <div key={index} >
              <h6 className={styles.smallHeading}>{heading.heading}</h6>
              {heading?.texts.map((text, idx) => (
                <p key={idx} className={styles.text}>
                  {renderTextWithLinks(text, heading.links || {})}
                </p>
              ))}
            </div>
          ))}
          <Link className={styles.link} to='/disclaimer'>
            Terms and Conditions
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Article;
