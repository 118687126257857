import { forwardRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import CloseIcon from "../../icons/CloseIcon";
import DropdownDown from "../../icons/DropdownDown";
import DropdownUp from "../../icons/DropdownUp";
import styles from "./BurgerMenu.module.css";
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton";

interface BurgerMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const BurgerMenu = forwardRef<any, BurgerMenuProps>(({ isOpen, onClose }: BurgerMenuProps, ref) => {
  const [isOpenCoins, setIsOpenCoins] = useState(false);
  const [isOpenResources, setIsOpenResources] = useState(false);
  const togglingCoins = () => setIsOpenCoins(!isOpenCoins);
  const togglingResources = () => setIsOpenResources(!isOpenResources);
  return (
    <button ref={ref} className={`${styles.burgerMenu} ${isOpen ? styles.open : ""}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "62px",
          width: "100%",
        }}
      >
        <Link to="/">
          <img src="/full_logo.svg" className={styles.logo} alt="logo" />
        </Link>
        <CloseIcon onClick={onClose} />
      </div>
      <div className={styles.scrollBar}>
        <nav className={styles.nav}>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.active}` : styles.link
                }
                to="/news"
                onClick={onClose}
              >
                News
              </NavLink>
            </li>
            <li>
              <p
                className={`${styles.dropdownHeading} ${
                  isOpenCoins && styles.opacity
                }`}
                onClick={togglingCoins}
                style={{ width: "77px" }}
              >
                <span>Coins</span>
                {isOpenCoins ? <DropdownUp /> : <DropdownDown />}
              </p>
              {isOpenCoins && (
                <div className={styles.dropDownList}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.link} ${styles.active}` : styles.link
                    }
                    onClick={() => {
                      togglingCoins();
                      onClose();
                    }}
                    style={{ marginBottom: "24px", fontSize: "18px" }}
                    to="/coins/compass"
                  >
                    <img
                      width={24}
                      height={24}
                      src="/H-coin_small_blue.svg"
                      alt="compass"
                    />
                    HEDGE - Compass
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.link} ${styles.active}` : styles.link
                    }
                    onClick={() => {
                      togglingCoins();
                      onClose();
                    }}
                    style={{ fontSize: "18px" }}
                    to="/coins/arbitrage"
                  >
                    <img
                      width={24}
                      height={24}
                      src="/H-coin_small_red.svg"
                      alt="arbitrage"
                    />
                    HEDGE - Arbitrage
                  </NavLink>
                </div>
              )}
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.active}` : styles.link
                }
                to="/bridge"
                onClick={onClose}
              >
                Bridge
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.active}` : styles.link
                }
                to="/redeem"
                onClick={onClose}
              >
                Redeem
              </NavLink>
            </li>
            <li>
              <p
                className={`${styles.dropdownHeading} ${
                  isOpenResources && styles.opacity
                }`}
                onClick={togglingResources}
                style={{ width: "123px" }}
              >
                <span>Resources</span>
                {isOpenResources ? <DropdownUp /> : <DropdownDown />}
              </p>
              {isOpenResources && (
                <div className={styles.dropDownList}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.link} ${styles.active}` : styles.link
                    }
                    onClick={() => {
                      togglingResources();
                      onClose();
                    }}
                    style={{ marginBottom: "24px", fontSize: "18px" }}
                    to="/disclaimer"
                  >
                    Terms and Conditions
                  </NavLink>
                  <Link
                    onClick={() => {
                      togglingResources();
                      onClose();
                    }}
                    to="/HEDGE_Whitepaper.pdf"
                    target="_blank"
                    className={styles.wpLink}
                    style={{ fontSize: "18px" }}
                  >
                    Whitepaper
                  </Link>
                </div>
              )}
            </li>
          </ul>
        </nav>
        <Link
          to="/buy"
          onClick={onClose}
          className={`${styles.button} ${styles.primary}`}
        >
          Buy
        </Link>
        <span>
          <ConnectWalletButton />
        </span>
      </div>
    </button>
  );
});

BurgerMenu.displayName = "BurgerMenu";

export default BurgerMenu;
