import * as React from "react";
import { SVGProps } from "react";
const DropdownDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={6}
    fill="none"
    {...props}
  >
    <path fill="#000" d="m0 .75 4.5 4.5L9 .75H0Z" />
  </svg>
);
export default DropdownDown;
