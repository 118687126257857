import Link from "../Link/Link";
import styles from "./NewsCard.module.css";

interface NewsCardProps {
  src: string;
  title: string;
  text: string;
  to: string;
}

const NewsCard = ({ src, title, text, to }: NewsCardProps) => {
  return (
    <div className={styles.wrapper}>
      <img src={src} alt="img" className={styles.img} />
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.text}>{text}</p>
      <Link to={to} />
    </div>
  );
};

export default NewsCard;
