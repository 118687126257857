import { ReactNode } from 'react';
import styles from './CryptoCard.module.css';

interface CryptoCardProps {
  src: string;
  value?: string;
  status: ReactNode;
}

const CryptoCard = ({ src, value = '', status }: CryptoCardProps) => {
  return (
    <div className={styles.wrapper}>
      <img src={src} alt='img' width={250} className={styles.img} />
      <div style={{ display: 'flex' }}>
        {value && <span className={styles.value}>{value}</span>}
        {status}
      </div>
    </div>
  );
};

export default CryptoCard;
