import { useCallback, useEffect, useRef, useState } from "react";
import ArrowRightMd from "../../icons/ArrowRightMd";
import styles from "./BuyBalance.module.css";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import {
  Allowance,
  ApproveDollar,
  DecimalValue,
  FloorPrice,
  MaxFeePercent,
  TokenBalance,
  buyToken,
} from "../../web3/Contracts/TokenMarketPlace";
import { parseUnits } from "viem";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Form, Input } from "antd";
import { config } from "../../config/config";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { useBalance } from "wagmi";
import {
  useGetFloorPriceQuery,
  useGetMinBuyAmountQuery,
  useGetMinTaxFeeQuery,
  useGetPortfolioValueQuery,
  useGetWalletBalanceQuery,
} from "../../services/Auth";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import ERC20Abi from "../../web3/ABI/UsdtAbi.json";

const StyledButton = styled(Button)`
  &:hover {
    background-color: transparent !important;
    border: none !important;
    span {
      color: #0058a0 !important;
    }
  }
`;

const StyledInput = styled(Input)`
  border: none !important;
  background: white;
  padding-right: 10px;
  box-shadow: none !important;

  & > input {
    font-weight: bold;
  }
  & > input:focus {
    box-shadow: none !important;
  }
`;
const BuyBalance = () => {
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);
  const { address } = useAccount();
  const [form] = useForm();
  const { data: getMinTaxFee } = useGetMinTaxFeeQuery("");
  const [loading, setLoading] = useState(false);
  const { writeContractAsync, data, error, isError } = useWriteContract();
  const navigate = useNavigate();
  const selectedConfig = useSelector(
    (state: any) => state.global.selectedConfig
  );
  const blockChainNetwork = useSelector((state: any) => state.global.network);
  const waitMetod = useWaitForTransactionReceipt({
    confirmations: 1,
    hash: `0x${data?.slice(2)}`,
  });
  const { data: getFloorPrice } = useGetFloorPriceQuery({
    network: blockChainNetwork.toUpperCase(),
  });
  const owner = address;
  const { data: getPortfolioValue } = useGetPortfolioValueQuery({
    network: blockChainNetwork.toUpperCase(),
    address: address,
  });
  const contractAddress = selectedConfig.Dollar_Token_Address;
  const [decimals, setDecimals] = useState<any>({
    dollar: DecimalValue(selectedConfig.Dollar_Token_Address)?.decimalValue,
    hedge: DecimalValue(selectedConfig.Hedge_Token_Address)?.decimalValue,
  });
  const [AllowanceAmount, setAllowanceAmount] = useState(
    Allowance(contractAddress, owner, selectedConfig)?.allowance /
      10 ** decimals
  );
  let refetchAllowance = Allowance(
    contractAddress,
    owner,
    selectedConfig
  )?.refetchAllowance;
  let refetchDollarDecimals = DecimalValue(
    selectedConfig.Dollar_Token_Address
  )?.refetchDecimals;
  let refetchHedgeDecimals = DecimalValue(
    selectedConfig.Hedge_Token_Address
  )?.refetchDecimals;

  useEffect(() => {
    if (isError && error) {
      console.log(error?.message);
      const message = error?.message?.split(":");
      const messageEnd = message?.[1]?.split("\n");
      toast.error(
        "Transaction reverted due to: " +
          message?.[1]?.slice(0, messageEnd?.[1]?.length + 1) +
          " or User rejected the transaction!!"
      );
      setLoading(false);
      setIsBuy(false);
    }
  }, [error, isError]);

  const getAllowanceValue = useCallback(async () => {
    let response = await refetchAllowance();
    return Promise.resolve(Number(response?.data));
  }, [refetchAllowance]);

  const getDecimalValue = useCallback(async () => {
    try {
      let responseDollar = await refetchDollarDecimals();
      let responseHedge = await refetchHedgeDecimals();
      return {
        dollarDecimals: Promise.resolve(responseDollar),
        hedgeDecimals: Promise.resolve(responseHedge),
      };
    } catch (err) {
      console.log(err);
      toast.error("something went wrong");
    }
  }, [refetchHedgeDecimals, refetchDollarDecimals, selectedConfig]);

  useEffect(() => {
    getAllowanceValue().then((data) => {
      setAllowanceAmount(Number(data) / 10 ** decimals?.dollar);
    });
  }, [getAllowanceValue, decimals]);

  useEffect(() => {
    getDecimalValue().then((data) => {
      data?.dollarDecimals?.then((dollar) => {
        setDecimals((prev: any) => {
          return {
            ...prev,
            dollar: dollar?.data,
          };
        });
      });
      data?.hedgeDecimals?.then((hedge) => {
        setDecimals((prev: any) => {
          return {
            ...prev,
            hedge: hedge?.data,
          };
        });
      });
    });
  }, [getDecimalValue, selectedConfig]);

  useEffect(() => {
    let timer = setInterval(() => {
      getAllowanceValue().then((data) => {
        setAllowanceAmount(Number(data) / 10 ** decimals?.dollar);
      });
    }, 2000);
    return () => clearInterval(timer);
  }, [getAllowanceValue, decimals]);

  const [check, setCheck] = useState(false);
  const [allowanceCheck, setAllowanceCheck] = useState(false);
  const { chainId } = useAccount();
  const { data: getWalletBalance } = useGetWalletBalanceQuery({
    address: address,
  });
  const { data: getMinBuyAmount } = useGetMinBuyAmountQuery({
    network: blockChainNetwork,
  });
  const [maxFee, setMaxFee] = useState(0);
  const [showFee, setShowFee] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [approveBuy, setApproveBuy] = useState(0);
  const [isBuy, setIsBuy] = useState(false);
  const [formValues, setFormValues] = useState<any>({
    UsdtValue: "",
    HdgValue: "",
  });
  const [isApproved, setIsApproved] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (waitMetod.isSuccess && isApproved) {
      toast.success("Token Approved successfully");
      setLoading(false);
      setAllowanceCheck(false);
    } else if (waitMetod.isSuccess && isBuy) {
      toast.success("Token Bought successfully");
      setLoading(false);
      setIsBuy(false);
    }
  }, [waitMetod.isSuccess, isApproved, isBuy]);

  const [balances, setBalances] = useState<{
    hedgePrice: any;
    usdcPrice: any;
    binancePrice: any;
  }>({
    hedgePrice: 0,
    usdcPrice: 0,
    binancePrice: 0,
  });

  const maxFeePercent = MaxFeePercent(selectedConfig, "buy");
  const getMaxFee = (dollarValue: any) => {
    setShowFee(dollarValue * (Number(maxFeePercent) / 100));
    if (blockChainNetwork === "Ethereum") {
      const fee = Number(
        (
          (dollarValue / getFloorPrice?.data) *
          (Number(maxFeePercent) / 100)
        ).toFixed(2)
      );
      return fee;
    } else {
      const fee = Number(
        (
          (dollarValue / getFloorPrice?.data) *
          (Number(maxFeePercent) / 100)
        ).toFixed(2)
      );
      return fee;
    }
  };
  const handleChange = (e: any) => {
    const dollarValue = e.target.value;
    setApproveBuy(dollarValue);
    let maxFeeValue = getMaxFee(dollarValue);
    setMaxFee(maxFeeValue);
    form.setFieldValue(
      "HdgValue",
      dollarValue / getFloorPrice?.data - maxFeeValue
    );
    if (AllowanceAmount >= Number(dollarValue)) {
      setCheck(true);
    } else {
      setCheck(false);
    }

    console.log(dollarValue, "dollarValue");
  };

  useEffect(() => {
    form.setFieldValue("UsdtValue", 0);
    form.setFieldValue("HdgValue", 0);
  }, []);

  let { refetch: refetchHedgePrice, value: hedgePrice } = TokenBalance({
    tokenAddress: selectedConfig.Hedge_Token_Address,
    userAddress: address,
  });

  let { refetch: refetchUsdcBalance, value: usdcPrice } = TokenBalance({
    tokenAddress: selectedConfig.Dollar_Token_Address,
    userAddress: address,
  });

  let binancePrice = useBalance({
    address: address,
  });

  const getPrices = async () => {
    await refetchHedgePrice();
    await refetchUsdcBalance();
  };

  useEffect(() => {
    let interval = setInterval(() => {
      getPrices();
    }, 15000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setBalances((prev) => {
      return {
        ...prev,
        hedgePrice,
        usdcPrice,
        binancePrice,
      };
    });
  }, [chainId, selectedConfig]);
  let currencyList = [
    {
      label: "HEDGE Compass",
      ticker: "HCS",
      price: (Number(hedgePrice) / 10 ** decimals?.hedge).toFixed(3),
      icon: "hedge-icon1.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
    {
      label: "Tether",
      ticker: "USDT",
      price: (Number(usdcPrice) / 10 ** decimals?.dollar).toFixed(3),
      icon: "tether_icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
    {
      label: "Binance",
      ticker: "BNB",
      price: Number(getWalletBalance?.data?.bnbBalance).toFixed(3) || "-",
      icon: "binance-icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
    {
      label: "Ethereum",
      ticker: "ETH",
      price: Number(getWalletBalance?.data?.ethBalance).toFixed(3) || "-",
      icon: "ethereum-icon.svg",
      address: selectedConfig.Hedge_Token_Address,
    },
  ];

  const handleBuy = async (values: any) => {
    try {
      if (AllowanceAmount >= values?.UsdtValue) {
        setLoading(true);
        setIsBuy(false);
        const amount = parseUnits(values?.UsdtValue, decimals?.dollar);
        const result = await buyToken(
          writeContractAsync,
          amount,
          address,
          selectedConfig,
          setLoading,
          setOpenConfirmationModal
        );
        await refetchHedgePrice({ throwOnError: true });
        refetchUsdcBalance();
        form.resetFields();
        setIsApproved(false);
        setIsBuy(true);
      } else {
        setLoading(true);
        setIsApproved(false);
        setAllowanceCheck(true);
        setOpenConfirmationModal(false);

        const amount = Number(config?.MAX_APPROVAL) * 10 ** decimals?.dollar;
        const tokenAddress = selectedConfig.Dollar_Token_Address;
        const response = await ApproveDollar(
          writeContractAsync,
          amount,
          address,
          tokenAddress,
          selectedConfig,
          setLoading,
          setOpenConfirmationModal,
          ERC20Abi
        );
        setFormValues(values);
        setIsApproved(true);
        setValue(values?.UsdtValue);
        setOpenConfirmationModal(true);
      }
    } catch (err) {
      setOpenConfirmationModal(false);
      setLoading(false);
      setIsApproved(false);
      setIsBuy(false);
      form.resetFields();
      setShowFee(0);
    }
  };
  const onModalBuy = async () => {
    setLoading(true);
    setIsBuy(false);
    const amount2 = Number(formValues?.UsdtValue) * 10 ** decimals?.dollar;
    try {
      const result = await buyToken(
        writeContractAsync,
        amount2,
        address,
        selectedConfig
      );

      refetchHedgePrice();
      refetchUsdcBalance();
      form.resetFields();
      setIsBuy(true);
      setOpenConfirmationModal(false);
      setLoading(false);
      setIsApproved(false);
      setAllowanceCheck(false);
    } catch (err) {
      setLoading(false);
      setIsBuy(false);
      setAllowanceCheck(false);
    }
  };

  const handleKeyDown = (event: any) => {
    // Add specific key handling logic if needed
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  return (
    <section className={styles.wrapper}>
      <div>
        <div className={styles.cardsWrapper}>
          <div className={styles.innerWrapper}>
            <div className={styles.buttonsGroup}>
              <button
                onClick={() => navigate("/buy-balance")}
                className={styles.buyButton}
              >
                <span>Buy</span>
              </button>
              <button
                onClick={() => navigate("/redeem-balance")}
                className={styles.redeemButton}
              >
                <span>Redeem</span>
              </button>
            </div>

            <div className={styles.header}>
              <div className={styles.headerTitle}>Account 1</div>
              <div className={styles.wallet}>
                <label className={styles.walletLabel}>{address}</label>
                <div className={styles.walletButton}>
                  <img src="/copy-icon.svg" alt="copy_icon" />
                  <button
                    className={styles.copyLabel}
                    onClick={() => {
                      navigator.clipboard.writeText(address?.toString() || "");
                      toast.success("Copied.!");
                    }}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>

            <div className={styles.subHeader}>
              <div className={styles.subHeaderTitle}>Current Balance</div>
              <div className={styles.divider} />
            </div>

            <div className={styles.portfolio}>
              <label className={styles.portfolioLabel}>Portfolio Balance</label>
              <div className={styles.portfolioAmount}>
                <div className={styles.amount}>
                  ${" "}
                  {getPortfolioValue?.data &&
                    Number(getPortfolioValue?.data)?.toFixed(3)}
                </div>
              </div>
            </div>

            <div className={styles.currencyWrapper}>
              {currencyList.map((currency, i) => {
                return (
                  <div key={i} className={styles.currencyCard}>
                    <div className={styles.currencyIcon}>
                      <img
                        width={"70px"}
                        src={`/${currency.icon}`}
                        alt="currency_icon"
                      />
                    </div>
                    <div className={styles.ticker}>
                      <div className={styles.currency}>
                        <div className={styles.currencyName}>
                          {currency.label}
                        </div>
                        <div className={styles.currencyTicker}>
                          {currency.ticker}
                        </div>
                      </div>
                      <div className={styles.price}>
                        <div className={styles.tooltip}>
                          <div className={styles.currencyPrice}>
                            {currency.price}
                          </div>
                          <span className={styles.tooltiptext}>
                            {currency.price}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <Form
              form={form}
              onFinish={handleBuy}
              className={styles.amountWrapper}
            >
              <>
                <div className={styles.amountTitle}>
                  <label className={styles.label}>Amount</label>
                  <label className={styles.label}>
                   Max Fee:
                    <span>{showFee} USDT</span>{" "}
                  </label>
                </div>
                <div className={styles.convertWrapper}>
                  <div>
                    <Form.Item
                      name="UsdtValue"
                      style={{
                        marginBottom: "0",
                        width: "100%",
                        borderRadius: "12px",
                        paddingRight: "10px",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },

                        ({}) => ({
                          validator(_, value) {
                            if (!(getMinBuyAmount?.data >= +value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                `Please enter value more than ${getMinBuyAmount?.data} Minimum amount`
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <StyledInput
                        suffix="USDT"
                        className={styles.inputField}
                        type="number"
                        style={{}}
                        placeholder="0"
                        onChange={handleChange}
                        onWheel={(event) => event.currentTarget.blur()}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Item>
                  </div>

                  <div className={styles.convert}>
                    <img
                      style={{ width: "22px" }}
                      src="/convert-gray-icon.svg"
                      alt="convert_icon"
                    />
                  </div>

                  <div className={styles.input}>
                    <Form.Item
                      name="HdgValue"
                      style={{
                        marginBottom: "0",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <StyledInput
                        className={styles.inputField}
                        type="number"
                        placeholder="0"
                        readOnly
                      />
                    </Form.Item>
                    <div className={styles.currency}>HCS</div>
                  </div>
                </div>

                <StyledButton
                  htmlType="submit"
                  className={`${styles.button} ${activeClass}`}
                  onMouseEnter={() => setActiveClass(styles.mouseEnter)}
                  onMouseLeave={() => setActiveClass(styles.mouseLeave)}
                  loading={loading || isBuy}
                  disabled={loading}
                >
                  {loading || isBuy ? (
                    <span>Pending</span>
                  ) : (
                    <span>Buy HEDGE Compass</span>
                  )}

                  <ArrowRightMd />
                </StyledButton>
              </>
            </Form>
          </div>
        </div>
      </div>
      <ConfirmationModal
        setOpenConfirmationModal={setOpenConfirmationModal}
        openConfirmationModal={openConfirmationModal}
        approveBuyAmount={approveBuy}
        buy="buy"
        btnAction={onModalBuy}
        allowanceAmount={AllowanceAmount}
        loading={loading}
        isApproved={isApproved}
        value={value}
        allowanceCheck={allowanceCheck}
      />
    </section>
  );
};

export default BuyBalance;
