import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  selectedConfig: "",
  network: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    setSelectedConfig: (state, action) => ({
      ...state,
      selectedConfig: action.payload,
    }),
    setNetwork: (state, action) => ({
      ...state,
      network: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setSelectedConfig, setNetwork } = globalSlice.actions;

export default globalSlice.reducer;
