import { SVGProps } from "react";
const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M1.1 20.9a1 1 0 0 1 0-1.415L19.486 1.101A1 1 0 0 1 20.9 2.514L2.515 20.899a1 1 0 0 1-1.414 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M1.1 1.1a1 1 0 0 1 1.415 0l18.384 18.385a1 1 0 0 1-1.414 1.415L1.101 2.514a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CloseIcon;
