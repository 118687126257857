import styles from './Disclaimer.module.css';

const Disclaimer = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.breadcrumbs}>
        Home &gt; Resources &gt; Terms and Conditions
      </div>
      <div className={styles.innerWrapper}>
        <h1 className={styles.heading}>Terms and Conditions</h1>
        <div className={styles.border} />
        <h6 className={styles.subHeading}>
          The information provided in these online materials does not constitute
          investment advice, financial advice, trading advice, or any other sort
          of advice. You should not treat any of the material’s content as such.
        </h6>
        <div className={styles.content}>
          This material is for informational purposes only and is not (i) an
          offer, or solicitation of an offer, to invest in, or to buy or sell,
          any interests or shares, or to participate in any investment or
          trading strategy, (ii) intended to provide accounting, legal, or tax
          advice, or investment recommendations.
        </div>
        <div className={styles.content}>
          Hedge Holdings Limited does not recommend that any
          cryptocurrency should be bought, sold, or held by you. Do conduct your
          due diligence and consult your financial advisor before making any
          investment decisions. By purchasing any HEDGE token or interacting
          monetarily with Hedge Holdings Limited you agree that you are
          not purchasing a security or investment, and you agree to hold the
          company and its associated parties harmless and agree that they are
          not liable for any losses or taxes you may incur. You also agree that
          the team is presenting the tokens "as is" and is not required to
          provide any support or services. You should have no expectation of any
          form of support (monetarily or otherwise) from Hedge Holdings Limited
          Limited, any employee, contractors or associated parties.
        </div>
        <div className={styles.content}>
          Hedge Holdings Limited strongly recommends that citizens in
          areas with government restrictions on cryptocurrencies do not purchase
          any HEDGE tokens because the company cannot ensure compliance with
          established regulations within certain territories. Always make sure
          that you comply with your local laws and regulations before you make
          any purchase.
        </div>
        <div className={styles.content}>
          Please note that there are always risks associated with
          cryptocurrencies and smart contracts. Please use them at your own
          risk. Hedge Holdings Limited is not a registered broker,
          analyst, or investment advisor. Everything that is provided in this
          material is purely for informational and educational purposes only. All
          information contained herein should be independently verified and
          confirmed. Hedge Holdings Limited does not accept any liability for
          any loss or damage whatsoever caused in reliance upon such information
          or services. Please be aware of the risks involved with any trading in
          any market, financial or otherwise. Do not trade with money that you
          cannot afford to lose. When in doubt, you should always consult a
          qualified financial advisor before making any investment decisions.
        </div>
      </div>
    </section>
  );
};

export default Disclaimer;
