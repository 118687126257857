import styled from "styled-components";
import { Button, Modal } from "antd";

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px !important;
  h2 {
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    padding-top: 30px;
    color: red;
  }
  .body {
    width: 100%;
    height: 100%;
    margin-top: 40px;
  }
  .ant-modal-content {
    min-height: 800px !important;
    min-width: 500px !important;
  }
  .ant-modal-title {
    height: 60px !important;
    padding: 20px;
    font-size: 28px;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 35px;
    background: white !important;
    width: 90%;
    margin: 60px auto !important;
    padding: 30px;
    margin-top: 30px;
    border-radius: 20px;
    background: rgba(227, 242, 255, 0.96) !important;
    h2 {
      font-size: 22px;
      text-align: center;
      font-weight: 700;
    }
    h4 {
      font-size: 16px;
      color: black;
    }
  }
  .container-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    padding: 15px;
    border-radius: 20px;
    padding-bottom: 40px;
    padding-top: 50px;
    margin-bottom: 30px;

    p {
      font-size: 22px;
      font-weight: 700;
    }
    span {
      font-size: 28px;
      color: #0058a0;
    }
  }
  .btn-container {
    width: 100%;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;
export const StyledButton = styled(Button)`
  width: 170px;
  height: 45px;
  span {
    font-size: 22px;
  }
`;

const ConfirmationModal = ({
  setOpenConfirmationModal,
  openConfirmationModal,
  btnAction,
  loading,
  buy,
  isApproved,
  value,
  allowanceCheck,
  bridge,
}: any) => {
  const handleCancel = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <StyledModal
      open={openConfirmationModal}
      title={"CONFIRMATION"}
      footer={null}
      onCancel={handleCancel}
      maskClosable={false}
    >
      {bridge ? (
        <h2>You have approved the bridging of Hedge.</h2>
      ) : (
        <h2>
          You have approved the {buy ? "purchase" : "redemption"} of Hedge.
        </h2>
      )}

      <div className="container">
        <div className="container-body">
          {bridge ? (
            <h1
              style={{
                fontSize: "32px",
                textAlign: "center",
                padding: "10px 10px 40px 10px",
              }}
            >
              Please confirm to complete the bridging of your tokens.
            </h1>
          ) : (
            <h1
              style={{
                fontSize: "32px",
                textAlign: "center",
                padding: "10px 10px 40px 10px",
              }}
            >
              Please confirm to complete your {buy ? "purchase" : "redemption"}{" "}
              of Hedge.
            </h1>
          )}
        </div>
        <div className="btn-container">
          <StyledButton onClick={handleCancel} loading={loading}>
            Cancel
          </StyledButton>
          <StyledButton type="primary" onClick={btnAction} loading={loading}>
            {loading ? "Pending" : "Confirm"}
          </StyledButton>
        </div>
      </div>
    </StyledModal>
  );
};

export default ConfirmationModal;
