import { useReadContract, useWaitForTransactionReceipt } from "wagmi";
import TokenMarketPlaceAbi from "../ABI/TokenMarketPlaceAbi.json";
import ERC20 from "../ABI/ERC20.json";
import toast from "react-hot-toast";

export const FloorPrice = (selectedConfig) => {
  const response = useReadContract({
    abi: TokenMarketPlaceAbi,
    address: selectedConfig.Tokenplace_Market_Contract_Address,
    functionName: "floorPrice",
  });
  const floorPriceValue = response?.data;

  return Number(floorPriceValue) / 1000;
};

export const Allowance = (contractAddress, owner, selectedConfig) => {
  const response = useReadContract({
    abi: ERC20,
    address: contractAddress,
    functionName: "allowance",
    args: [owner, selectedConfig.Tokenplace_Market_Contract_Address],
  });
  const allowanceValue = response?.data;
  return {allowance:Number(allowanceValue), refetchAllowance:response?.refetch};
};

export const TokenBalance = ({ tokenAddress, userAddress }) => {
  const response = useReadContract({
    abi: ERC20,
    address: tokenAddress,
    functionName: "balanceOf",
    args: [userAddress],
    refetchInterval: 1000,
  });
  return { value: response?.data, refetch: response?.refetch };
};

export const DecimalValue = (address) => {
  const response = useReadContract({
    abi: ERC20,
    address: address,
    functionName: "decimals",
  });
  return {decimalValue:response?.data, refetchDecimals:response?.refetch};;
};

export const ApproveDollar = async (
  writeContract,
  amount,
  address,
  tokenAddress,
  selectedConfig,
  setLoading,
  setOpenConfirmationModal,
  ERC20Abi
) => {
  
  try {
  const response =  await writeContract({
      abi: ERC20Abi,
      address: tokenAddress,
      functionName: "approve",
      args: [selectedConfig.Tokenplace_Market_Contract_Address, amount],
      from: address,
    });
  } catch (err) {
    console.log(err);
    setLoading(false);
    setOpenConfirmationModal(false);
    throw new Error(err)
  }
};

export const MaxFeePercent = (selectedConfig, buy) => {
  const response = useReadContract({
    abi: TokenMarketPlaceAbi,
    address: selectedConfig.Tokenplace_Market_Contract_Address,
    functionName: buy?"buyTax":"taxAmount",
  });
  return response?.data;
};

export const buyToken = async (
  writeContract,
  amount,
  address,
  selectedConfig,
  setLoading,
  setOpenConfirmationModal,
) => {
  try {
    const response = await writeContract({
      abi: TokenMarketPlaceAbi,
      address: selectedConfig.Tokenplace_Market_Contract_Address,
      functionName: "buyTokens",
      args: [amount],
      from: address,
    });

    return response;
  } catch (Err) {
    console.log(Err);
    setLoading(false);
    setOpenConfirmationModal(false);
    
    throw new Error(Err);
  }
};
export const WaitTransaction = (txHash) => {
  const result = useWaitForTransactionReceipt({
    hash: txHash,
  });
  return result;
};
export const redeemToken = async (
  writeContract,
  amount,
  address,
  selectedConfig,
  setLoading,
  setOpenConfirmationModal,
) => {
  try {
    await writeContract({
      abi: TokenMarketPlaceAbi,
      address: selectedConfig.Tokenplace_Market_Contract_Address,
      functionName: "redeem",
      args: [amount],
      from: address,
    });
  } catch (err) {
    console.log(err);
    setLoading(false);
    setOpenConfirmationModal(false);
    throw new Error(err);
  }
};

export const bridgeToken = async ({
  selectedConfig,
  amount,
  address,
  writeContract,
}) => {
  try {
    await writeContract({
      abi: TokenMarketPlaceAbi,
      address: selectedConfig.Tokenplace_Market_Contract_Address,
      functionName: "bridgeToken",
      args: [amount],
      from: address,
    });
  } catch (err) {
    console.log(err);
  }
};
