import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../BaseQuery";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface InitialState {
  token: String;
}

const initialState: InitialState = {
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken } = authSlice.actions;

export default authSlice.reducer;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: rtkBaseQuery,
  endpoints: (builder) => ({
    getSignMessage: builder.query({
      query: () => {
        return {
          method: "GET",
          url: "/auth/message",
        };
      },
    }),
    walletLogin: builder.mutation({
      query: (data) => {
        return {
          method: "POST",
          body: data,
          url: "/auth",
        };
      },
    }),
    getMinTaxFee: builder.query({
      query: () => {
        return {
          method: "GET",
          url: "/user/fee",
        };
      },
    }),
    getWalletBalance: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: "/user/wrapped-balance",
          params: params,
        };
      },
    }),
    subscribeEmail: builder.mutation({
      query: (data) => {
        return {
          method: "POST",
          url: "/public/subscribe-email",
          body: data,
        };
      },
    }),
    getMinRedeemBalance: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: "/public/minimum-redeem",
          params: { network: params.network.toUpperCase() },
        };
      },
    }),
    getPortfolioValue: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: "/user/balance",
          params: { network: params.network.toUpperCase(), address: params.address },
        };
      },
    }),
    getFloorPrice: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: "/public/floor-price",
          params: { network: params.network.toUpperCase() },
        };
      },
    }),
    getMinBuyAmount: builder.query({
      query: (params) => {
        return {
          method: "GET",
          url: "/public/minimum-buy",
          params: { network: params.network.toUpperCase() },
        };
      },
    }),
    
  }),
});

export const {
  useGetSignMessageQuery,
  useWalletLoginMutation,
  useGetWalletBalanceQuery,
  useGetMinTaxFeeQuery,
  useGetMinRedeemBalanceQuery,
  useSubscribeEmailMutation,
  useGetPortfolioValueQuery,
  useGetFloorPriceQuery,
  useGetMinBuyAmountQuery
} = authApi;
