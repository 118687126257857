import { ConnectButton } from "@rainbow-me/rainbowkit"
import styled from "styled-components";

const ConnectWalletButton = ({showBalance}:any) => {

  const StyledDiv= styled.div`
  display: flex;
  justify-content: flex-end;
    
  `
  return (
    <StyledDiv style={{marginBottom: "10px"}}><ConnectButton showBalance={showBalance} accountStatus="avatar" chainStatus="icon"/></StyledDiv>
  )
}

export default ConnectWalletButton