import * as React from "react";
import { SVGProps } from "react";
const DropdownUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={9}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#000" d="m9 6.75-4.5-4.5L0 6.75h9Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9 9H0V0h9z" />
      </clipPath>
    </defs>
  </svg>
);
export default DropdownUp;
