import { useEffect, useState } from "react";
import ArrowRightMd from "../../icons/ArrowRightMd";
import styles from "./Redeem.module.css";
import { FloorPrice } from "../../web3/Contracts/TokenMarketPlace";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import ConnectWalletModal from "../../components/ConnectWalletModal/ConnectWalletModal";
import { useGetFloorPriceQuery } from "../../services/Auth";

const Redeem = () => {
  const [activeClass, setActiveClass] = useState(styles.mouseLeave);
  const selectedConfig = useSelector((state:any)=>state.global.selectedConfig)
  const navigate = useNavigate();
  const {address, isConnected}= useAccount();
  const [openModal, setOpenModal ] = useState(false);
  const blockChainNetwork = useSelector((state: any) => state.global.network);
  const {data: getFloorPrice} = useGetFloorPriceQuery({network: blockChainNetwork.toUpperCase()});

  const handleClick =()=>{
    if(address){

      navigate("/redeem-balance");
    }else{
      toast.error("Please connect your wallet first");
      setOpenModal(true);
      
    }

  }
  
  return (
    <section className={styles.wrapper}>
      <div className={styles.breadcrumbs}>Home &gt; Redeem</div>
      <div>
        <h1 className={styles.heading}>Redeem</h1>
        <h6 className={styles.subHeading}>
          You can always redeem your HEDGE tokens at the current floor price.
        </h6>
        <div className={styles.cardsWrapper}>
          <div className={styles.cardWrapper}>
            <img
              style={{ marginBottom: "15px", width: "215px" }}
              src="/C_with_dash.svg"
              alt="compass"
            />
            <span className={styles.floorPrice}>Floor Price</span>
            <span className={styles.value}>${getFloorPrice?.data || "-"}</span>
          </div>

          <div className={styles.cardWrapper}>
            <img
              style={{ marginBottom: "15px", width: "215px" }}
              src="/A_with_dash.svg"
              alt="arbitrage"
            />
            <span className={styles.floorPrice}>Floor Price</span>
            <span className={styles.value}>Coming soon</span>
          </div>
        </div>
        <button
          className={`${styles.button} ${activeClass}`}
          onMouseEnter={() => setActiveClass(styles.mouseEnter)}
          onMouseLeave={() => setActiveClass(styles.mouseLeave)}
          onClick={() => handleClick()}
        >
          <span >
          {address? "Redeem": "Connect to Redeem"}
          </span>
          <ArrowRightMd />
        </button>
      </div>
      <ConnectWalletModal openModal={openModal} setOpenModal={setOpenModal}/>
    </section>
  );
};

export default Redeem;
