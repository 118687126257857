import { Modal } from "antd";
import React from "react";
import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton";
import styled from "styled-components";
import { useAccount } from "wagmi";

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    background: #0e76fd;
    text-align: center;
    padding-top: 10px;
    border-radius: 20px;
    color: white;
    font-weight: 1000;
    font-size: 16px;
    font-family: Inter , Arial, sans-serif;
    padding: 10px 10px 0 10px;
    margin: 30px;
  }
`;
const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px !important;
  .body {
    width: 100%;
    height: 100%;
    margin-top: 40px;
  }
  .ant-modal-content {
    min-height: 300px !important;
    max-width: 300px !important;
  }
`;
const ConnectWalletModal = ({ openModal, setOpenModal }: any) => {
  const{address} = useAccount();
  return (
    <StyledModal
      title="Confirm Selection"
      open={openModal}
      footer={null}
      onCancel={() => setOpenModal(false)}
    >
      <div className="body">
        <p
          style={{
            fontSize: "20px",
            padding: "20px",
            textAlign: "center",
            fontWeight: "800",
          }}
        >
          Please connect your wallet first!!
        </p>
        <StyledDiv>
          <span>
            {!address &&<ConnectWalletButton showBalance={false} />}
          </span>
        </StyledDiv>
      </div>
    </StyledModal>
  );
};

export default ConnectWalletModal;
