import { ReactElement, useRef, useState } from "react";
import { Link, NavLink} from "react-router-dom";
import Burger from "../../icons/Burger";
import DropdownDown from "../../icons/DropdownDown";
import DropdownUp from "../../icons/DropdownUp";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import styles from "./Header.module.css";

import ConnectWalletButton from "../ConnectWalletButton/ConnectWalletButton";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

function Header(): ReactElement {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);
  const [isOpenCoins, setIsOpenCoins] = useState<boolean>(false);
  const [isOpenResources, setIsOpenResources] = useState<boolean>(false);
  const burgerMenuRef = useRef<any>(null);
  const toggleBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const closeBurgerMenu = () => {
    setIsBurgerMenuOpen(false);
  };
  const togglingCoins = () => setIsOpenCoins(!isOpenCoins);
  const togglingResources = () => setIsOpenResources(!isOpenResources);

  useOnClickOutside(burgerMenuRef, ()=>setIsBurgerMenuOpen(false))


  const StyledConnectButton = styled.div`
    @media screen and (max-width: 1000px) {
      display: none;
    }
  `;

  return (
    <header className={styles.header}>
      <Link to="/">
        <img src="/full_logo.svg" className={styles.fullLogo} alt="logo" />
      </Link>
      <nav>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : styles.link
              }
              to="/news"
            >
              News
            </NavLink>
          </li>
          <li>
            <div className={styles.dropdownContainer}>
              <p className={styles.dropdownHeading} onClick={togglingCoins}>
                <span>Coins</span>
                {isOpenCoins ? <DropdownUp /> : <DropdownDown />}
              </p>
              {isOpenCoins && (
                <div
                  className={styles.dropDownList}
                  onMouseLeave={() => setIsOpenCoins(false)}
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.link} ${styles.active}` : styles.link
                    }
                    onClick={togglingCoins}
                    style={{
                      marginBottom: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    to="/coins/compass"
                  >
                    <img
                      width={24}
                      height={24}
                      src="/H-coin_small_blue.svg"
                      alt="compass"
                    />
                    HEDGE - Compass
                  </NavLink>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.link} ${styles.active}` : styles.link
                    }
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={togglingCoins}
                    to="/coins/arbitrage"
                  >
                    <img
                      width={24}
                      height={24}
                      src="/H-coin_small_red.svg"
                      alt="arbitrage"
                    />
                    HEDGE - Arbitrage
                  </NavLink>
                </div>
              )}
            </div>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : styles.link
              }
              to="/bridge"
            >
              Bridge
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.link} ${styles.active}` : styles.link
              }
              to="/redeem"
            >
              Redeem
            </NavLink>
          </li>
          <li>
            <div className={styles.dropdownContainer}>
              <p
                className={styles.dropdownHeading}
                onClick={togglingResources}
                style={{ width: "97px" }}
              >
                <span>Resources</span>
                {isOpenResources ? <DropdownUp /> : <DropdownDown />}
              </p>
              {isOpenResources && (
                <div
                  className={styles.dropDownList}
                  onMouseLeave={() => setIsOpenResources(false)}
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? `${styles.link} ${styles.active}` : styles.link
                    }
                    onClick={togglingResources}
                    style={{ marginBottom: "16px" }}
                    to="/disclaimer"
                  >
                    Terms and Conditions
                  </NavLink>
                  <Link
                    onClick={togglingResources}
                    to="/HEDGE_Whitepaper.pdf"
                    target="_blank"
                    className={styles.wpLink}
                  >
                    Whitepaper
                  </Link>
                </div>
              )}
            </div>
          </li>
        </ul>
      </nav>

      <div className={styles.buttonContainer}>
        <Link
          to="/buy"
          className={`${styles.button} ${styles.primary}`}
          style={{ marginBottom: "10px" }}
        >
          Buy
        </Link>
        <StyledConnectButton>
          <ConnectWalletButton />
        </StyledConnectButton>

        <Burger className={styles.burger} onClick={toggleBurgerMenu} />
      </div>
      <BurgerMenu ref={burgerMenuRef} isOpen={isBurgerMenuOpen} onClose={closeBurgerMenu} />
    </header>
  );
}

export default Header;
