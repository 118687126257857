export const config = {
  Tokenplace_Market_Contract_Address:
    process.env.REACT_APP_TOKEN_MARKET_PLACE_APP,
  Dollar_Token_Address: process.env.REACT_APP_DOLLAR_TOKEN_ADDRESS,
  CHAINS: process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT,
  Hedge_Token_Address: process.env.REACT_APP_HEDGE_TOKEN_ADDRESS,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  MAX_APPROVAL: process.env.REACT_APP_MAX_APPROVAL_AMOUNT,
  Balance_Address : process.env.REACT_APP_BALANCE_ADDRESS
};

export const configBinance = {
  Tokenplace_Market_Contract_Address:
    process.env.REACT_APP_TOKEN_MARKET_PLACE_APP_BINANCE,
  Dollar_Token_Address: process.env.REACT_APP_DOLLAR_TOKEN_ADDRESS_BINANCE,
  Hedge_Token_Address: process.env.REACT_APP_HEDGE_TOKEN_ADDRESS_BINANCE,
  Balance_Address : process.env.REACT_APP_BALANCE_ADDRESS_BINANCE,
};


