import { HTMLAttributes } from 'react';
import Link from '../../../../components/Link/Link';
import styles from './BigCryptoCard.module.css';

interface BigCryptoCardProps extends HTMLAttributes<HTMLDivElement> {
  img: string;
  title: string;
  subTitle: string;
  text: string;
  to: string;
}

const BigCryptoCard = ({
  img,
  title,
  subTitle,
  text,
  to,
  ...props
}: BigCryptoCardProps) => {
  return (
    <div className={styles.wrapper} {...props}>
      <img src={img} alt='img' className={styles.img} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
        <img src={title} alt='img' className={styles.title} />
        <h3 className={styles.subTitle}>{subTitle}</h3>
        <p className={styles.text}>{text}</p>
        <Link to={to} />
      </div>
    </div>
  );
};

export default BigCryptoCard;
