// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BigCryptoCard_wrapper__ICoaT {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.BigCryptoCard_subTitle__NiTu6 {
  color: #5a5a5a;
  font-family: Inter, Arial, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

.BigCryptoCard_title__StRaR {
  width: 324px;
  height: 35px;
  margin-bottom: 24px;
}

.BigCryptoCard_text__f4s1e {
  max-width: 540px;
  color: #5c5c5c;
  font-family: Inter, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 40px;
}

.BigCryptoCard_img__tETTt {
  width: 310px;
  height: 310px;
  margin-bottom: 15px;
}

@media screen and (width >= 1024px) {
  .BigCryptoCard_wrapper__ICoaT {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }

  .BigCryptoCard_img__tETTt {
    width: initial;
    height: initial;
    margin-bottom: 0;
  }

  .BigCryptoCard_title__StRaR {
    width: 434px;
    height: 47px;
    margin-bottom: 26px;
  }

  .BigCryptoCard_subTitle__NiTu6 {
    font-size: 32px;
  }

  .BigCryptoCard_text__f4s1e {
    font-size: 22px;
    line-height: 38px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/BigCryptoCard/BigCryptoCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE;IACE,mBAAmB;IACnB,8BAA8B;IAC9B,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  text-align: left;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 16px;\n}\n\n.subTitle {\n  color: #5a5a5a;\n  font-family: Inter, Arial, sans-serif;\n  font-size: 26px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 48px;\n}\n\n.title {\n  width: 324px;\n  height: 35px;\n  margin-bottom: 24px;\n}\n\n.text {\n  max-width: 540px;\n  color: #5c5c5c;\n  font-family: Inter, Arial, sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 33px;\n  margin-bottom: 40px;\n}\n\n.img {\n  width: 310px;\n  height: 310px;\n  margin-bottom: 15px;\n}\n\n@media screen and (width >= 1024px) {\n  .wrapper {\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 0;\n  }\n\n  .img {\n    width: initial;\n    height: initial;\n    margin-bottom: 0;\n  }\n\n  .title {\n    width: 434px;\n    height: 47px;\n    margin-bottom: 26px;\n  }\n\n  .subTitle {\n    font-size: 32px;\n  }\n\n  .text {\n    font-size: 22px;\n    line-height: 38px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BigCryptoCard_wrapper__ICoaT`,
	"subTitle": `BigCryptoCard_subTitle__NiTu6`,
	"title": `BigCryptoCard_title__StRaR`,
	"text": `BigCryptoCard_text__f4s1e`,
	"img": `BigCryptoCard_img__tETTt`
};
export default ___CSS_LOADER_EXPORT___;
