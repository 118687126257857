// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CryptoCard_wrapper__t\\+YqQ {
  padding: 24px 41px 24px 36px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 22px;
  background: rgba(227, 242, 255, 0.9);
  max-width: 626px;
  width: 100%;
  margin-bottom: 10px;
  z-index: 1;
}

.CryptoCard_value__HUdut {
  font-size: 23px;
  font-weight: 600;
  margin-right: 16px;
}

.CryptoCard_img__MPvWi {
  margin-bottom: 24px;
}

@media screen and (width >= 1024px) {
  .CryptoCard_wrapper__t\\+YqQ {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .CryptoCard_img__MPvWi {
    margin-bottom: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/CryptoCard/CryptoCard.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,mBAAmB;EACnB,oCAAoC;EACpC,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".wrapper {\n  padding: 24px 41px 24px 36px;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  border-radius: 22px;\n  background: rgba(227, 242, 255, 0.9);\n  max-width: 626px;\n  width: 100%;\n  margin-bottom: 10px;\n  z-index: 1;\n}\n\n.value {\n  font-size: 23px;\n  font-weight: 600;\n  margin-right: 16px;\n}\n\n.img {\n  margin-bottom: 24px;\n}\n\n@media screen and (width >= 1024px) {\n  .wrapper {\n    justify-content: space-between;\n    align-items: center;\n    flex-direction: row;\n  }\n\n  .img {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CryptoCard_wrapper__t+YqQ`,
	"value": `CryptoCard_value__HUdut`,
	"img": `CryptoCard_img__MPvWi`
};
export default ___CSS_LOADER_EXPORT___;
