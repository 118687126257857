import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet, sepolia, bscTestnet, bsc } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { config } from "../config/config";

declare module 'wagmi' { 
  interface Register { 
    config: typeof config 
  } 
} 
console.log(config.CHAINS, "config.CHAINS");
 export const wagmiConfig = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "eb863bbc71d3cd24ecb3b07d69d0cd56",
  chains: config.CHAINS === "testnet" ? [bscTestnet, sepolia] : [mainnet, bsc],
  ssr: false, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();

const WithWagmiProvider = ({ children }: { children: React.ReactNode }) => {
 
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default WithWagmiProvider;
